export const sort_by = {
  NONE:0,
  NAME:1,
  PRICE:2,
  RATING:3,
  DISTANCE:4,
}

export const order_by={
  NONE:0,
  HIGH_TO_LOW:1,
  LOW_TO_HIGH:2,
  A_TO_Z:3,
  Z_TO_A:4,
}

export const filter_by={
  NONE:"",
  MALE:"MALE",
  FEMALE:"FEMALE",
  UNISEX:"UNISEX",
}