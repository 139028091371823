import React, { useEffect, useState } from "react"
import { window } from "browser-monads"
import axios from "axios";
import {URLS} from "../../config/urls"
import { navigate } from "gatsby";
export const userContext = React.createContext({
  user:null
})
const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    window.JSON.parse(window.localStorage.getItem(localStorageKey)) || null
  )

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(value))
  }, [value])

  return [value, setValue]
}

const UserContext = props => {
  
  const [token, setToken] = useStateWithLocalStorage("treatoUser")
  const [user,setUser] = useState(null)
  const updateUserData = ()=>{
    axios.get(URLS.userDetailUrl(), {
      headers: {
        Authorization: "Token " + token
      }
    }).then(({data})=>{
      setUser(data)
    }).catch(()=>setToken(null))
  }
  useEffect(()=>{
    if(!user)
    updateUserData();
  },[token])
  return(
    <userContext.Provider value = {{user,setUser,setToken,token,updateUserData}}>
      {props.children}
    </userContext.Provider>
  )
}

export default UserContext
