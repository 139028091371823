export const backendHost = process.env.REACT_APP_BACKEND;
export const base = ''
export const URLS = {
  loginUrl() {
    return `${backendHost}/users/login/`
  },

  logoutUrl() {
    return `${backendHost}/users/auth/token/logout/`
  },

  registerUrl() {
    return `${backendHost}/users/account/`
  },

  sendOTPUrl() {
    return `${backendHost}/users/account/send-otp`
  },

  verifyOTPUrl() {
    return `${backendHost}/users/account/verify-otp`
  },
  verifyCoupon() {
    return `${backendHost}/users/current/verify_coupon`
  },
  fetchCoupon() {
    return `${backendHost}/users/current/coupons`
  },
  fetchSubscriptionList() {
    return `${backendHost}/subscriptions/durations_list/`
  },
  subscriptionUrl() {
    return `${backendHost}/subscriptions/subscribe/`
  },

  serviceSearchUrl(term) {
    return `${backendHost}/search/salon-services/suggest/?name_suggest__completion=${term}`
  },

  salonSearchUrl(term) {
    return `${backendHost}/search/salons/suggest/?name_suggest__completion=${term}`
  },

  salonsSearchByLocationUrl(lat, long) {
    return `${backendHost}/search/salons/?location__geo_distance=5km__${lat}__${long}`
  },

  salonsBySubCategory(id) {
    return `${backendHost}/salons/service-sub-category/${id}/services/`
  },

  subcategoriesUrl() {
    return `${backendHost}/salons/service-sub-category`
  },

  servicesWithCategoriesUrl() {
    return `${backendHost}/salons/category/services`
  },

  salonDetailsUrl(id) {
    return `${backendHost}/salons/${id}`
  },

  allSalons() {
    return `${backendHost}/salons/`
  },

  salonServicesUrl(id) {
    return `${backendHost}/salons/${id}/services/`
  },

  salonMembersUrl(id) {
    return `${backendHost}/salons/${id}/team-members/`
  },

  salonReviewsUrl(id) {
    return `${backendHost}/salons/${id}/reviews/`
  },
  salonBookingsUrl(id) {
    return `${backendHost}/salons/${id}/public_bookings/`
  },
  createBookings(id) {
    return `${backendHost}/salons/${id}/bookings/`
  },
  reschedulingUrl() {
    return `${backendHost}/salons/reschedule_booking/`
  },
  createBookingPublic(id) {
    return `${backendHost}/salons/${id}/public_bookings/`
  },
  serviceBaseCategoryUrl() {
    return `${backendHost}/salons/service-base-category/`
  },
  getOrderId() {
    return `${backendHost}/salons/create_order/`
  },
  updateTransaction() {
    return `${backendHost}salons/store_booking_transaction/`
  },
  userDetailUrl() {
    return `${backendHost}/users/current/`
  },
  storeBookingTransaction() {
    return `${backendHost}/salons/verify_booking_transaction/`
  },
  cancelBooking() {
    return `${backendHost}/salons/cancel_booking/`
  },
  updatePasswordUrl: () => {
    return `${backendHost}/users/account/update-password`
  },

  applyCoupon: () => {},

  salonPackages: id => {
    return `${backendHost}/salons/${id}/service-packages/`
  },

  forgotPassword: () => {
    return `${backendHost}/users/account/forgot-password`
  },
  popularServices: () => {
    return `${backendHost}/salons/popular/services`
  },
  popularCategory: () => {
    return `${backendHost}/salons/popular/categories`
  },
  googleLogin: () => {
    return `${backendHost}/users/google/`
  },
  facebookLogin: () => {
    return `${backendHost}/users/facebook/`
  },
  editProfile: id => {
    return `${backendHost}/users/account/${id}/`
  },
}
