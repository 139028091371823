import axios from "axios"
import { URLS } from "../../config/urls"


export class SearchContextServices {
  async getAllSalons() {
    let { data } = await axios.get(URLS.allSalons())
    return [].concat(data.results)
  }

  async getAllSalonsWithServices() {
    let { data: salonData } = await axios.get(URLS.allSalons())
    console.log(salonData);
    let object = {}

    if (salonData.count > 0) {
      salonData.results.forEach((store) => {
        store["rating"] = 0
        store["services"] = []
        store["reviews"] = []
        object[store.id] = store
      })
      let requests = []
      salonData.results.forEach(salon => requests.push(axios.get(URLS.salonServicesUrl(salon.id))))
      let serviceResp = await axios.all(requests)
      serviceResp.forEach(({ data }) => {
        if (data.count) {
          object[data.results[0].salon.id].services = data.results
        }
      })
      requests = []
      salonData.results.forEach(salon => requests.push(axios.get(URLS.salonReviewsUrl(salon.id))))
      serviceResp = await axios.all(requests)
      serviceResp.forEach(({ data }) => {
        if (data.length){
          object[data[0].salon.id].reviews = data
          object[data[0].salon.id].rating = data.reduce((sum, review) => {
            return sum + (review.cleanliness_score + review.ambience_score + review.staff_score) / 3
          }, 0) / data.length
        }
      })
    }
    return { list: [].concat(salonData.results), object: object }
  }

  async searchForTerm(term, salonObject) {
    let [{ data: nameData }, { data: serviceData }] = await axios.all([
      axios.get(URLS.salonSearchUrl(term)),
      axios.get(URLS.serviceSearchUrl(term))
    ])
    // //debugger
    let searchData = {}

    nameData["name_suggest__completion"][0]["options"].map(salon => {
      searchData[salon["_source"].id] = true
    })
    serviceData["name_suggest__completion"][0]["options"].map(service => {
      searchData[JSON.parse(service["_source"]["salon"])[0]["pk"]] = true
    })
    let results = [].concat(Object.keys(searchData).map(key => salonObject[key]));
    return results.filter(result => result !== undefined);
  }

  async searchForLocation({ lat, long }, salonObject) {
    console.log("Fetching data for == ", lat, ",", long)
    let { data: locationSearch } = await axios.get(URLS.salonsSearchByLocationUrl(lat, long))
    console.log()
    return [].concat(locationSearch.results.map(({ id }) => salonObject[id]))
  }

  async getSalon(id) {
    //debugger
    let [
      { data: salonData },
      { data: { results: services } },
      { data: { results: stylists } },
      { data: bookings },
      { data: { results: packages } }] = await axios.all([
      axios.get(URLS.salonDetailsUrl(id)),
      axios.get(URLS.salonServicesUrl(id)),
      axios.get(URLS.salonMembersUrl(id)),
      axios.get(URLS.salonBookingsUrl(id)),
      axios.get(URLS.salonPackages(id))
    ])

    let bookingDetails = bookings.reduce((prev, singleBooking) => prev.concat(singleBooking["services_and_packages"]), [])


    packages.forEach(singlePackage => {
      singlePackage.services = services.filter(({ id: serviceId }) => {
        return singlePackage.services.includes(serviceId)
      })
    })

    salonData["services"] = null
    salonData["stylists"] = null
    salonData["bookings"] = null
    salonData["packages"] = null

    let serviceObject = {}
    let packageObject = {}
    packages.forEach(singlePackage => packageObject[singlePackage.id] = singlePackage)
    services.forEach(service => serviceObject[service.id] = service)

    salonData.services = serviceObject
    salonData.packages = packageObject
    salonData.stylists = stylists
    salonData.bookings = bookingDetails

    return salonData
  }

  async getSalonsBySubCategory(id,salonObject) {
    let { data: subCategoryList } = await axios.get(URLS.salonsBySubCategory(id))
    return [].concat(subCategoryList.map(({ salon: { id } }) => salonObject[id])
      .reduce((comb, current) => {
        if (!comb.find(salon => salon.id == current.id)) comb.push(current);
        return comb;
      }, [])
    )
  }

}