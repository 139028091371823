import React, { useEffect, useState } from "react"
import { sort_by, order_by, filter_by } from "./filterConstants"

export const filterContext = React.createContext({
  sortBy:3,
  orderBy:1,
})

const FilterProvider = (props) => {
  const [sortBy, setSortBy] = useState(sort_by.RATING)
  const [orderBy, setOrderBy] = useState(order_by.HIGH_TO_LOW)
  const [filterBy,setFilterBy] = useState(filter_by.NONE)

  return (
    <filterContext.Provider value={{
      sortBy,
      orderBy,
      filterBy,
      setFilterBy,
      setSortBy: (type) => {
        switch (type) {
          case (sort_by.NAME):
            setSortBy(sort_by.NAME)
            setOrderBy(order_by.A_TO_Z)
            break
          case (sort_by.NONE):
            setSortBy(sort_by.NONE)
            setOrderBy(order_by.NONE)
            break
          default:
            // debugger
            if ([order_by.A_TO_Z, order_by.Z_TO_A, order_by.NONE].includes(orderBy)) {
              if (type === sort_by.RATING) {
                setOrderBy(order_by.HIGH_TO_LOW)
              } else {
                setOrderBy(order_by.LOW_TO_HIGH)
              }
            }
            setSortBy(type)
        }
      },
      setOrderBy: (order) => {

        if (sortBy === sort_by.NAME) {
          switch (order) {
            case order_by.A_TO_Z:
            case order_by.Z_TO_A:
              setOrderBy(order)
          }
        } else {
          switch (order) {
            case order_by.HIGH_TO_LOW:
            case  order_by.LOW_TO_HIGH:
              setOrderBy(order)
          }
        }
      }
    }}>
      {props.children}
    </filterContext.Provider>
  )
}

export default FilterProvider