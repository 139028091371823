import React, { useEffect, useState } from "react"
import { CategoryContextService } from "../categoryContext/categoryContextService"

export const categoryContext = React.createContext([])

const CategoryContext = props => {
  const [categoryList, setCategoryList] = useState([])
  const [baseCategoryList, setBaseCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])

  const ccs = new CategoryContextService()

  useEffect(() => {
    if (!categoryList.length) {
      ccs
        .getServiceBaseCategory()
        .then(result => {
          // setCategoryList(result)
          setBaseCategoryList(result.baseCategory)
          setSubCategoryList(result.subCategory)
        })
        .catch(err => console.log(err))
    }
  }, [])
  return (
    <>
      {/* <categoryContext.Provider value={categoryList}>
        {props.children}
      </categoryContext.Provider> */}

      <categoryContext.Provider
        value={{
          baseCategory: baseCategoryList,
          subCategory: subCategoryList,
        }}
      >
        {props.children}
      </categoryContext.Provider>
    </>
  )
}

export default CategoryContext
