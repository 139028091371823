import React, { useEffect, useState } from "react"
import { window } from "browser-monads"

export const cartContext = React.createContext({
  cartValue: [{ price: 0 }],
})

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    window.JSON.parse(window.localStorage.getItem(localStorageKey)) || {
      salon: null,
      services: [],
      packages: [],
      coupon:null,
    subscription:null,
    }
  )

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value])

  return [value, setValue]
}

const CartProvider = (props) => {
  const [cartValue, setCartValue] = useStateWithLocalStorage(
    "treatoCart"
  )

  return (
    <cartContext.Provider value={{
      cartValue,
      emptyCart: () => {
        setCartValue({
          salon: null,
          services: [],
          packages: [],
          coupon:null,
          subscription:null,
        })
      },
      setCartValue: (value) => {
        setCartValue(value)
      },
      truncatePersons:()=>{
        let newCartValue = {...cartValue}
        cartValue.services.forEach(service=>service.persons=1)
        cartValue.packages.forEach(singlePackage=>singlePackage.persons=1)
      },
      setCoupon: (couponData)=>{
        let newCartValue = {...cartValue}
        newCartValue.coupon = couponData
        setCartValue(newCartValue)
      },
      setSubscription: (subscriptionData)=>{
        if(!subscriptionData)subscriptionData=null
        let newCartValue = {...cartValue}
        newCartValue.subscription = subscriptionData
        setCartValue(newCartValue)
      },
      addServiceToCart: (service) => {
        if (cartValue.salon !== null && cartValue.salon !== service.salon.id)
          return false
        if (cartValue.services.filter(({ id }) => id === service.id).length)
          return true
        let newService = { ...service }
        newService.persons = 1
        let newValue = { ...cartValue }
        if (!cartValue.salon) {
          newValue.salon = newService.salon.id
        }
        newValue.services.push(newService)
        setCartValue(newValue)
        return true
      },
      addPackageToCart: (singlePackage) => {
        if (cartValue.salon !== null && cartValue.salon !== singlePackage.salon)
          return false
        if (cartValue.packages.filter(({ id }) => id === singlePackage.id).length)
          return true
        let newPackage = { ...singlePackage }
        newPackage.persons = 1
        let newValue = { ...cartValue }
        if (!cartValue.salon) {
          newValue.salon = newPackage.salon
        }
        newValue.packages.push(newPackage)
        setCartValue(newValue)
        return true
      },
      removeService: (id) => {
        let newValue = { ...cartValue }
        newValue.services = newValue.services.filter((service) => service.id !== id)
        if (!newValue.services.length && !newValue.packages.length)
          newValue ={
            salon: null,
            services: [],
            packages: [],
            coupon:null,
            subscription:null,
          }
        setCartValue(newValue)
      },
      removeFromList: (list,id) => {
        let newList = [...list]
        return newList.filter((service) => service.id !== id)
      },
      removePackage: (id) => {
        let newValue = { ...cartValue }
        newValue.packages = newValue.packages.filter((singlePackage) => singlePackage.id !== id)
        if (!newValue.packages.length && !newValue.services.length)
          newValue = {
          salon: null,
          services: [],
          packages: [],
          coupon:null,
          subscription:null,
        }
        setCartValue(newValue)
      },
      addPersonToService: (id) => {
        //debugger
        let newValue = { ...cartValue }
        for (let service of newValue.services) {
          if (service.id === id) {
            service.persons = service.persons + 1
            break
          }
        }
        setCartValue(newValue)
      },
      addPersonToPackage: (id) => {
        //debugger
        let newValue = { ...cartValue }
        for (let singlePackage of newValue.packages) {
          if (singlePackage.id === id) {
            singlePackage.persons = singlePackage.persons + 1
            break
          }
        }
        setCartValue(newValue)
      },
      addPersonToList: (list,id) => {
        let newList = [...list]
        for (let singlePackage of newList) {
          if (singlePackage.id === id) {
            singlePackage.persons = singlePackage.persons + 1
            break
          }
        }
        return newList
      },
      removePersonFromService: (id) => {
        let newValue = { ...cartValue }
        for (let service of newValue.services) {
          if (service.id === id) {
            if (service.persons > 1)
              service.persons = service.persons - 1
            break
          }
        }
        setCartValue(newValue)
      },
      removePersonFromList: (list,id) => {
        let newList = [...list]
        for (let service of newList) {
          if (service.id === id) {
            if (service.persons > 1)
              service.persons = service.persons - 1
            break
          }
        }
        return newList
      },
      removePersonFromPackage: (id) => {
        let newValue = { ...cartValue }
        for (let singlePackage of newValue.packages) {
          if (singlePackage.id === id) {
            if (singlePackage.persons > 1)
              singlePackage.persons = singlePackage.persons - 1
            break
          }
        }
        setCartValue(newValue)
      }

    }}>
      {props.children}
    </cartContext.Provider>
  )
}

export default CartProvider