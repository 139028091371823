import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { URLS } from "../../config/urls"
import { window } from "browser-monads"
import { userContext } from "../userContext/userContext"
import { navigate } from "gatsby"
export const razorpayContext = React.createContext({
  razorpay:false
})
function RazorpayContext(props) {
  const userCtx = useContext(userContext)
  const [available,setAvailable] = useState(false)

  const makePayment = ({ order_id,first_name,phone_number,email },success,error)=>{
    const options = {
      key: "rzp_test_0ZA9xXp3yesUef",
      order_id: order_id,
      name: "Treato",
      image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
      escape:false,
        "modal": {
          "ondismiss": function(){
            error("Payment cancelled by user")
          }
        },
      handler: function(response) {
        axios.post(URLS.storeBookingTransaction(), {
          "razorpay_payment_id": response.razorpay_payment_id,
          "razorpay_order_id": response.razorpay_order_id,
          "razorpay_signature": response.razorpay_signature
        }).then(() => {
          success(response.razorpay_payment_id)
        }).catch(e=>error(e))
      },
      callback: URLS.storeBookingTransaction(),
      prefill: {
        name: first_name,
        contact: phone_number,
        email: email
      },
      theme: {
        color: "blue",
        hide_topbar: false
      }
    }
    const rzp1 = new window.Razorpay(options)
    rzp1.open()
  }
  const displayRazorpay = (data,success,error) => {
    let headers = {}
    if(userCtx.user)
    headers["Authorization"] = "Token " + userCtx.token
    axios.post(URLS.getOrderId(), { "booking_id": data.bookingId }, {
      headers: headers
    }).then(({ data: { order_id } }) => {
      makePayment({ ...data,order_id },success,error)
    }).catch(e=>error(e))
  }
  const displayRazorpaySubscription = (data,success,error) => {
    let headers = {}
    if(userCtx.user)
    headers["Authorization"] = "Token " + userCtx.token
    axios.post(URLS.getOrderId(), { "subscription_id": data.subscriptionId }, {
      headers: headers
    }).then(({ data: { order_id } }) => {
      makePayment({ ...data,order_id },success,error)
    }).catch(e=>error(e))
  }


  function loadScript(url, callback) {
    let script = document.createElement("script")
    script.type = "text/javascript"
    if (script.readyState) {  // only required for IE <9
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {  //Others
      script.onload = function() {
        callback()
      }
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
  }
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {
      setAvailable(true)
    })
  }, [])
  return (
    <razorpayContext.Provider value={{available,displayRazorpay,displayRazorpaySubscription}}>
      {props.children}
    </razorpayContext.Provider>
  )
}

export default RazorpayContext