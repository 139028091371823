/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/tachyons.min.css"
import "./src/styles/uikit.min.css"
import "./src/styles/styles.css"

import React from "react"
import SearchProvider from "./src/context/searchContext/searchContext"
import FilterProvider from "./src/context/filterContext/filterContext"
import CategoryContext from "./src/context/categoryContext/categoryContext"
import CartProvider from "./src/context/cartContext/cartContext"
import UserProvider from "./src/context/userContext/userContext"
import RazorpayProvider from "./src/context/razorpayContext/razorpayContext"

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <RazorpayProvider>
      <CartProvider>
        <CategoryContext>
          <FilterProvider>
            <SearchProvider>
              {element}
            </SearchProvider>
          </FilterProvider>
        </CategoryContext>
      </CartProvider>
    </RazorpayProvider>
  </UserProvider>

)