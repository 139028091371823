// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-done-js": () => import("./../src/pages/bookingDone.js" /* webpackChunkName: "component---src-pages-booking-done-js" */),
  "component---src-pages-booking-reschedule-js": () => import("./../src/pages/bookingReschedule.js" /* webpackChunkName: "component---src-pages-booking-reschedule-js" */),
  "component---src-pages-booking-schedule-js": () => import("./../src/pages/bookingSchedule.js" /* webpackChunkName: "component---src-pages-booking-schedule-js" */),
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listing-js": () => import("./../src/pages/listing.js" /* webpackChunkName: "component---src-pages-listing-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-test-js": () => import("./../src/pages/paymentTest.js" /* webpackChunkName: "component---src-pages-payment-test-js" */),
  "component---src-pages-salon-js": () => import("./../src/pages/salon.js" /* webpackChunkName: "component---src-pages-salon-js" */)
}

