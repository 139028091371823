import axios from "axios"
import { URLS } from "../../config/urls"

export class CategoryContextService {
  async getServiceBaseCategory() {
    let [
      { data: basecategoryList },
      { data: subCategoryList },
    ] = await axios.all([
      axios.get(URLS.serviceBaseCategoryUrl()),
      axios.get(URLS.subcategoriesUrl()),
    ])

    let CombinedData = {}
    basecategoryList.results.forEach(basecategory => {
      basecategory["subcategory"] = []
      CombinedData[basecategory.id] = basecategory
    })

    subCategoryList.results.forEach(subcategory => {
      CombinedData[subcategory.base.id].subcategory.push(subcategory)
    })

    let finalData = {
      baseCategory: basecategoryList.results,
      subCategory: subCategoryList.results,
    }

    // return [].concat(basecategoryList.results)
    return finalData
  }
}
