import React, { useContext, useEffect, useState } from "react"
import { SearchContextServices } from "./searchContextServices"

export const searchContext = React.createContext({
  defaultSalonList: [],
  defaultSalonObject: {},
  location: {},
  searchString: "",
  searchSalonList: []
})


const SearchProvider = props => {
  const [defaultSalonList, setDefaultSalonList] = useState([])
  const [defaultSalonObject, setDefaultSalonObject] = useState({})
  const [searchSalonList, setSearchSalonList] = useState([])
  const [searchString, setSearchString] = useState("")
  const [location, setLocation] = useState(null)
  const [searchedList, setSearchedList] = useState(null)
  const mcs = new SearchContextServices()

  useEffect(() => {
    if (location) {
      mcs.searchForLocation(location, defaultSalonObject).then(list => {
        setSearchSalonList(list)
      })

    }
  }, [location])

  useEffect(() => {
    console.log(searchSalonList);
  }, [searchSalonList]);

  return (
    <searchContext.Provider value={{
      getSalon: mcs.getSalon,

      getSalonsWithServices: () => {
        mcs.getAllSalonsWithServices().then(({ list, object }) => {
          setDefaultSalonList(list)
          setDefaultSalonObject(object)
          setSearchSalonList(list)
        })
      },
      searchSubCategoryBaseSalon: (id) => {
        mcs.getSalonsBySubCategory(id,defaultSalonObject).then(searchList => {
          console.log("Get salons by subcat:", searchList);
          setSearchSalonList(searchList)
        })
      },
      searchLocationBaseSalon: () => {
        mcs.searchForLocation(location, defaultSalonObject).then(list => {
          setSearchSalonList(list)
        })
      },
      searchTextBaseSalon: (serviceName) => {
        let searchString = serviceName || searchString
        if (searchString&&searchString !== "")
          mcs.searchForTerm(searchString, defaultSalonObject).then(list => {
            setSearchSalonList(list)
          })
        else {
          setSearchSalonList(defaultSalonList)
        }
      },
      setSearchSalonList,
      searchSalonList,
      searchString,
      setSearchString,
      location,
      setLocation,
      defaultSalonList,
      defaultSalonObject
    }}>
      {props.children}
    </searchContext.Provider>
  )
}

export default SearchProvider